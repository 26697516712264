<script setup>
// layout
import Layout from "../../layouts/main.vue";
import UploadImage from "../../components/uploadImage.vue";
// import thư viện
import mushroom from "cem-primary-api";
import { useRoute, useRouter } from "vue-router";
import { ref, reactive, onMounted } from "vue";
import { FormInstance } from "element-plus";
import toastr from "toastr";

// data khai báo
import MethodService from "../../service/MethodService";
import DataService from "../../service/DataService";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
import { langWeb } from "@/stores/lang";
const lang_web = langWeb();
const lang = ref(lang_web.showLang.toLocaleUpperCase());
import Data from "./data-dich-vu";
import LanguageService from "../../service/LanguageService";
import { watch } from "vue";
const ruleFormRef = ref(FormInstance);
const dataForm = reactive({
  value: MethodService.copyObject(Data.dataForm),
});
const validForm = reactive({ value: Data.validForm });
const listStatus = ref(DataService.listStatus);
const validateFlag = ref(false);
const listType = ["probe", "bras"];

// const listMenuHidden = reactive([
//   {
//     label: `Ẩn`,
//     value: true,
//   },
//   {
//     label: `Hiển thị`,
//     value: false,
//   },
// ]);
const router = useRoute();
const routerPush = useRouter();
let currentIndex = reactive({ value: 0 });
const propsIdAvatar = ref("");

// Lấy danh sách dịch vụ
const getService = async () => {
  let dataFilter = {
    limit: 99999,
  };
  try {
    const serviceApiRes = await mushroom.service.listAsync(dataFilter, {
      cacheAge: 1,
    });
    if (serviceApiRes.result) {
      currentIndex.value = serviceApiRes.meta.total;
    }
  } catch (e) {
    MethodService.showError(e.code);
  }
};

// thêm mới dịch vụ
const addService = (formEl) => {
  validateFlag.value = true;
  if (!formEl) return;
  formEl.validate(async (valid) => {
    if (valid) {
      const serviceData = {
        name: dataForm.value.name.trim(),
        fullname: dataForm.value.fullname.trim(),
        disabled: false,
        avatar_id: dataForm.value.avatar_id ? dataForm.value.avatar_id : null,
        index: currentIndex.value + 1,
        // is_menu_hidden: false,
        type: dataForm.value.type,
      };
      try {
        const idService = await mushroom.service.createAsync(serviceData);
        if (idService)
          toastr.success(t("toastr_add_success"));
        routerPush.go(-1);
      } catch (error) {
        if (error.code === 401 && error.meta.field === "$unique_key") {
          toastr.error(t("toastr_had_name"));
        } else {
          MethodService.showError(error.code);
        }
      }
    } else return false;
  });
};

// cập nhật dịch vụ
const updateService = (formEl) => {
  validateFlag.value = true;
  if (!formEl) return;
  formEl.validate(async (valid) => {
    if (valid) {
      const serviceData = {
        id: router.params.id,
        name: dataForm.value.name.trim(),
        fullname: dataForm.value.fullname.trim(),
        disabled: dataForm.value.disabled,
        avatar_id: dataForm.value.avatar_id ? dataForm.value.avatar_id : null,
        index: dataForm.value.index,
        // is_menu_hidden: dataForm.value.is_menu_hidden,
        type: dataForm.value.type,
      };
      try {
        const idService = await mushroom.service.partialUpdateAsync(
          serviceData
        );
        if (idService.result === router.params.id)
          toastr.success(t("toastr_update_success"));
        routerPush.go(-1);
      } catch (error) {
        if (error.code === 401 && error.meta.field === "$unique_key") {
          toastr.error(t("toastr_had_name"));
        } else {
          MethodService.showError(error.code);
        }
      }
    } else return false;
  });
};
// function lấy thông tin chi tiết dịch vụ
const getServiceByID = async () => {
  try {
    const response = await mushroom.service.findByIdAsync({
      id: router.params.id,
    });
    dataForm.value = response.result;
    dataForm.value.disabled = dataForm.value.disabled ? "true" : "false";
    propsIdAvatar.value = response.result?.avatar_id;
  } catch (e) {
    MethodService.showError(e.code);
  }
};

const getIdImageUpload = (idImageUpload) => {
  dataForm.value.avatar_id = idImageUpload;
};

const removeImageUpload = (data) => {
  propsIdAvatar.value = data;
  dataForm.value.avatar_id = data;
};

watch(
  () => lang_web.showLang,
  () => {
    lang.value = lang_web.showLang.toLocaleUpperCase();
    LanguageService.lang = LanguageService[lang.value];
    listStatus.value = MethodService.copyObject(DataService.listStatus)
    if (validateFlag.value === true)
      validForm.value = MethodService.copyObject(Data.validForm);
  }
);

onMounted(() => {
  if (router.name === "ThemMoiDichVu") {
    getService();
  } else if (router.name === "CapNhatDichVu") {
    getServiceByID();
  }
  // let activeMenu = document
  //   .querySelector("#navbar-nav")
  //   .querySelector('[href="' + "/service" + '"]');
  // activeMenu.classList.add("active");
});
</script>

<template>
  <Layout>
    <div class="card">
      <div class="card-header">
        <h3 v-if="router.name === 'ThemMoiDichVu'">
          {{ $t("t-add-service") }}
        </h3>
        <h3 v-else>{{ $t("t-update-service") }}</h3>
      </div>
      <div class="card-body">
        <div class="main-body">
          <el-form
            ref="ruleFormRef"
            :model="dataForm.value"
            :rules="validForm.value"
            status-icon
            label-width="200px"
            class="form-user"
          >
            <el-form-item :label="$t('t-service-name')" prop="name">
              <el-input
                type="text"
                autocomplete="off"
                v-model="dataForm.value.name"
                :placeholder="$t('t-place-service-name')"
              />
            </el-form-item>
            <el-form-item
              :label="$t('t-type-probe-bras')"
              :prop="router.name === 'ThemMoiDichVu' ? 'type' : ''"
            >
              <el-select
                v-model="dataForm.value.type"
                filterable
                :placeholder="$t('t-place-type-probe-bras')"
                :no-match-text="$t('t-match-data')"
                :no-data-text="$t('t-no-data')"
              >
                <el-option
                  v-for="item in listType"
                  :key="item"
                  :label="item"
                  :value="item"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              :label="$t('t-service-fullname')"
              prop="fullname"
            >
              <el-input
                type="text"
                autocomplete="off"
                v-model="dataForm.value.fullname"
                :placeholder="$t('t-place-service-fullname')"
              />
            </el-form-item>
            <el-form-item
              :label="$t('t-status')"
              prop="disabled"
              v-if="router.name === 'CapNhatDichVu'"
            >
              <el-select
                v-model="dataForm.value.disabled"
                filterable
                :placeholder="$t('t-place-status')"
                :no-match-text="$t('t-match-data')"
                :no-data-text="$t('t-no-data')"
              >
                <el-option
                  v-for="item in listStatus"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <!-- <el-form-item
              :label="$t('t-hide-on-menu')"
              prop="is_menu_hidden"
              v-if="router.name === 'CapNhatDichVu'"
            >
              <el-select
                v-model="dataForm.value.is_menu_hidden"
                filterable
                :placeholder="$t('t-place-status')"
              >
                <el-option
                  v-for="item in listMenuHidden"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item> -->
            <el-form-item label="" prop="">
              <upload-image
                :propsIdAvatar="propsIdAvatar"
                @uploadImage="getIdImageUpload"
                @removeImage="removeImageUpload"
              ></upload-image>
            </el-form-item>
            <div class="text-center">
              <button
                type="button"
                class="btn btn-soft-secondary btn-border mx-3"
                @click="routerPush.go(-1)"
              >
                {{ $t("t-back") }}
              </button>
              <button
                type="button"
                class="btn btn-secondary btn-border"
                @click="addService(ruleFormRef)"
                v-if="router.name === 'ThemMoiDichVu'"
              >
                {{ $t("t-add") }}
              </button>
              <button
                type="button"
                class="btn btn-secondary btn-border"
                @click="updateService(ruleFormRef)"
                v-else
              >
                {{ $t("t-update") }}
              </button>
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style lang="scss" scoped>
.image-zone {
  height: auto !important;
}
</style>
